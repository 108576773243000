@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,900;1,400;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2.1rem;
  font-family: 'Source Sans Pro', 'sans-serif';
  line-height: 2.5rem;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 1.9rem;
    font-family: 'Source Sans Pro', 'sans-serif';
    line-height: 2.1rem;
  }
}

p {
  line-height: 1.5rem;
}

a {
  color: #F86948;
  text-decoration: none;
}

a:hover {
  /* color: #F89248; */
  text-decoration: none;
}